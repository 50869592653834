<template>
  <div id="footersection" class="grey-background-darker mt-10">
    <v-footer color="app-background" padless>
      <v-row justify="center" no-gutters>
        <v-btn
          v-for="(link, index) in links"
          :key="index"
          color="white"
          text
          class="my-2"
          :to="link.link"
        >
          {{ link.title }}
        </v-btn>
        <v-col class="app-background py-4 text-center white--text" cols="12">
          <p class="caption">
            &copy; {{ new Date().getFullYear() }} DEAR MAMA FILM
          </p>
        </v-col>
      </v-row>
    </v-footer>
  </div>
</template>

<script>
export default {
  data() {
    return {
      links: [
        { title: "About", link: "/" },
        {
          title: "Special content",
          link: "/speakers",
        },
        { title: "Watch", link: "/watch" },
        { title: "Contact", link: "/contact" },
      ],
      legal_links: [
        { title: "Cookie Policy |", link: "" },
        { title: "Normativa sulla privacy", link: "" },
      ],
      social_icons: [
        {
          title: "mdi-facebook",
          link: "https://www.facebook.com/TechSoupItalia",
        },
        { title: "mdi-twitter", link: "https://twitter.com/TechSoupItalia" },
        {
          title: "mdi-linkedin",
          link: "https://it.linkedin.com/company/techsoup-italia",
        },
        {
          title: "mdi-instagram",
          link: "https://www.instagram.com/TechSoupItalia/",
        },
        {
          title: "mdi-youtube",
          link: "https://www.youtube.com/channel/UCg-d2n6t6Txa7lsKOYENaSQ",
        },
      ],
    };
  },
};
</script>

<style>
.grey-background-darker {
  background-color: #e6e6e6 !important;
}
</style>